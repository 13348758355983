import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Logo from "../components/UX/Logo"
import { RevealTitle } from "../components/UX/VisibilitySensor"
import Section from "../components/UX/Section"
import { GatsbyImage } from "gatsby-plugin-image"
import { PageButton } from "../components/UI/Buttons"

const DiscussionPage = ({ data }) => {
  const { title, statement } =
    data.allFile.edges[0].node.childMarkdownRemark.frontmatter
  const blogs = data.allMarkdownRemark.edges

  //Sort the array so that it's in date order.
  function compare(a, b) {
    var dateA = new Date(a.node.frontmatter.date).getTime()
    var dateB = new Date(b.node.frontmatter.date).getTime()

    if (dateA < dateB) return 1
    if (dateA > dateB) return -1
    return 0
  }

  blogs.sort(compare)

  const Discussion = blogs.map(({ node }, i) => {
    const { date, image, title, author } = node.frontmatter
    const slug = node.fields.slug
    const excerpt = node.excerpt

    return (
      <>
        <div key={i}>
          <GatsbyImage
            image={image.childImageSharp.gatsbyImageData}
            alt={title}
          />
          <div className="flex-col">
            <h2 style={{ textAlign: "left" }}>{title}</h2>
            <p>{excerpt}</p>
            <br></br>
            <span style={{ display: "flex", justifyContent: "space-between" }}>
              <p>{date}</p>
              <p>{author}</p>
            </span>
            <br></br>
            <PageButton to={slug} class="right black">
              Find Out More
            </PageButton>
          </div>
        </div>
      </>
    )
  })

  return (
    <Layout>
      <Seo title="Discussion" />
      <Section>
        <div className="container grid-by-4">
          <Logo />
          <div className="grid-right">
            <RevealTitle barStyle="right" directionUp={false}>
              <h1 style={{ textAlign: "left" }}>{title}</h1>
              <br></br>
              <p
                style={{
                  gridRowStart: "2",
                  gridColumnStart: "1",
                  alignItems: "end",
                }}
              >
                {statement}
              </p>
            </RevealTitle>
          </div>
        </div>
        <div className="container grid-3-by-3">{Discussion}</div>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  {
    allFile(filter: { name: { eq: "discussion" } }) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              title
              statement
            }
          }
        }
      }
    }
    allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { date: {}, cms: { eq: "blog" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          excerpt
          frontmatter {
            date
            author
            image {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [PNG, WEBP, AVIF]
                  layout: FULL_WIDTH
                )
              }
            }
            title
          }
        }
      }
    }
  }
`

export default DiscussionPage
